import { createContext } from "react";

export interface IUser {
  id: number,
  authorization: string,
  roles: string[]
}

export const UserContext = createContext<[
  IUser | null,
  React.Dispatch<React.SetStateAction<IUser | null>>]>
  (null as any);
