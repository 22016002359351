import { getServerUrl } from "../Utils";

type ILoginRequestData = {
  username: string;
  password: string;
}

export type ILoginResponse = {
  id: number;
  username: string;
  roles: string[];
}

export function convertToBasicAuthBase64(username: string, password: string) {
  return window.btoa(unescape(encodeURIComponent(username + ":" + password)));
}

export async function restLogin(data: ILoginRequestData, authorization: string) : Promise<ILoginResponse> {
  const controller = new AbortController();
  const response = await fetch(
      getServerUrl() + "/login",
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": "Basic " + authorization
        },
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: "username=" + data.username + "&password=" + data.password,
        signal: controller.signal
      });
  //controller.abort();
  //console.table(response);
  if (!response.ok)
    throw new Error(String(response.status));
  return await response.json();
}
