import { getServerUrl } from "../Utils";

export type IRow = {
  id?: number;
  name: string;
}

type IRowsRequestData = {
  id?: number;
  name?: string;
}

export type IRowsResponse = IRow[];

export async function restLoadRows(data: IRowsRequestData, authorization: string) : Promise<IRowsResponse> {
  const controller = new AbortController();
  const response = await fetch(
      getServerUrl() + "/producer/list?" + new URLSearchParams({ product: JSON.stringify(data) }),
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Basic " + authorization
        },
        method: "GET",
        mode: "cors",
        signal: controller.signal
      });
  //controller.abort();
  //console.table(response);
  if (!response.ok)
    throw new Error(String(response.status));
  return await response.json();
}

export type IRowRequestData = {
  id?: number;
}

export type IRowResponse = IRow;

export async function restLoadRow(data: IRowRequestData, authorization: string) : Promise<IRowResponse> {
  const controller = new AbortController();
  const response = await fetch(
      getServerUrl() + "/producer/get?" + new URLSearchParams({ producer: JSON.stringify(data) }),
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Basic " + authorization
        },
        method: "GET",
        mode: "cors",
        signal: controller.signal
      });
  //controller.abort();
  //console.table(response);
  if (!response.ok)
    throw new Error(String(response.status));
  return await response.json();
}

export async function restSaveRow(data: IRowRequestData, authorization: string) : Promise<void> {
  const controller = new AbortController();
  const response = await fetch(
      getServerUrl() + "/producer/insert",
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Basic " + authorization
        },
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data),
        signal: controller.signal
      });
  //controller.abort();
  //console.table(response);
  if (!response.ok)
    throw new Error(String(response.status));
}
