import { Grid, LinearProgress, Snackbar, Alert, Fab, Stack, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import { IRow, restLoadRows } from './RestApi';

interface IProps {
}

interface IFormData {
}

export const MasterForm: React.FC<IProps> = () => {
  const [t] = useTranslation();
  const defaultValues = {
  }
  const {handleSubmit, control, getValues, setValue, reset} = useForm<IFormData>();
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<IRow[]>();

  useEffect(() => {
    setLoading(true);
    restLoadRows(getValues(), user?.authorization || "")
        .then((response) => {
          setRows(response);
        })
        .catch((error) => {
          setError(error);
        });
    setLoading(false);
  }, []);
  
  const columns: GridColDef[] = [
    { field: "id", headerName: t("id"), flex: 1, hide: true },
    { field: "name", headerName: t("name"), flex: 1 }
  ];
  
  return (
    <Grid container spacing={2} direction="column" justifyContent="center" p={3}>
      <Grid item>
        { loading ? <LinearProgress/> : <></> }
      </Grid>
      <Grid item>
        <DataGrid
            rows={rows ? rows as any : []}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            autoHeight
            onRowClick={(row) => navigate("/detail", { state: { rowId: Number(row.id) }})}
            components={{
              Toolbar: GridToolbar,
            }}
        />
      </Grid>
      <Grid item>
        <Stack direction="row" justifyContent="end">
          <Fab color="primary" aria-label="add" onClick={ () => navigate("/detail")}>
            <MdAdd size="25px"/>
          </Fab>
        </Stack>
      </Grid>
      <Grid item>
          <Snackbar
              open={error !== undefined && error !== null}
              autoHideDuration={5000}
              message={ error && t(error.message) }
              onClose={ () => setError(null) }
              action={
                <IconButton size="small" color="inherit" onClick={() => setError(null)}>
                  <MdClose fontSize="small" />
                </IconButton>
              }
          />
      </Grid>
    </Grid>
  );
}
