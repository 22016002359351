import { getServerUrl } from "../Utils";

type IItemRequestData = {
  id: number;
  barcode: string;
  quantity: number;
}

export type IItemResponse = {
  id?: number;
  quantity?: number;
  weight?: number;
}

export async function restCreateNewItem(data: IItemRequestData, authorization: string) : Promise<IItemResponse> {
  const controller = new AbortController();
  const response = await fetch(
      getServerUrl() + "/product/insert",
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Basic " + authorization
        },
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data),
        signal: controller.signal
      });
  //controller.abort();
  //console.table(response);
  if (!response.ok)
    throw new Error(String(response.status));
  return {};
}

export async function restLoadItem(data: IItemRequestData, authorization: string) : Promise<IItemResponse> {
  const controller = new AbortController();
  const p = {
    barcode: data.barcode
  };
  const response = await fetch(
      getServerUrl() + "/product/get?" + new URLSearchParams({ product: JSON.stringify(p) }),
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Basic " + authorization
        },
        method: "GET",
        mode: "cors",
        signal: controller.signal
      });
  //controller.abort();
  //console.table(response);
  if (!response.ok)
    throw new Error(String(response.status));
  return await response.json();
}
