
import React, { FC, useContext } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';

interface IProps {
  children: JSX.Element;
}

export const PrivateRoute : FC<IProps> = (props) => {
  const [user] = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  if (user != null)
    return props.children;

  return (
    <Navigate to="/login" state={{ from: location }}/>
  );
}
