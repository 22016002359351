import { Button, Grid, IconButton, LinearProgress, Snackbar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { ControlledCheckbox } from '../controlled/ControlledCheckbox';
import { ControlledTextField } from '../controlled/ControlledTextField';
import { UserContext } from '../UserContext';
import { getLoginData, storeLoginData } from './LocalStorage';
import { convertToBasicAuthBase64, restLogin } from './RestApi';

export interface IFormData {
  username: string;
  password: string;
  remember: boolean;
}

export const LoginForm = () => {
  const [t] = useTranslation();
  const defaultValues = {
    username: "",
    password: "",
    remember: false
  }
  const {handleSubmit, control, reset, register} = useForm<IFormData>({ defaultValues });
  const [error, setError] = useState<Error | null>(null);
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const data = getLoginData();
    if (data != null) {
      setLoading(true);
      const authorization = convertToBasicAuthBase64(data.username, data.password);
      restLogin(data, authorization)
          .then((response) => {
            setUser({
              "id" : response.id,
              "authorization": authorization,
              "roles": response.roles
            });
            if (response.id != null)
              navigate(location.state.from ? location.state.from : "/");
          })
          .catch((error) => {
            setError(error);
          });
      setLoading(false);
    }
  }, []);

  const login = (data : IFormData) => {
    setLoading(true);
    storeLoginData(data);
    const authorization = convertToBasicAuthBase64(data.username, data.password);
    restLogin(data, authorization)
        .then((response) => {
          const authorization = convertToBasicAuthBase64(data.username, data.password);
          setUser({
            "id" : response.id,
            "authorization": authorization,
            "roles": response.roles
          });
          if (response.id != null)
            navigate(location.state.from ? location.state.from : "/");
        })
        .catch((error) => {
          setError(error);
        });
    setLoading(false);
  };

  return (
    <form>
      <Grid container spacing={2} direction="column" justifyContent="center" p={3}>
        <Grid item>
          <ControlledTextField
              name="username"
              control={ control }
              label={ t("username") }
              autoFocus={ true }
              required={ true }
          />
        </Grid>
        <Grid item>
          <ControlledTextField
              name="password"
              control={ control }
              type="password"
              label={ t("password") }
              required={ true }
          />
        </Grid>
        <Grid item>
          <ControlledCheckbox
                name="remember"
                control={ control }
                label={ t("rememberMe") }
          />
        </Grid>
        <Grid item>
          { loading ? <LinearProgress/> : <></> }
        </Grid>
        <Grid item>
          <Grid container spacing={2} direction="row" justifyContent="center">
            <Grid item xs={6}>
              <Button fullWidth type="submit" variant="contained" onClick={ handleSubmit(login) }>{ t('login')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth onClick={ () => reset(defaultValues) }>{ t('reset')}</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Snackbar
              open={error !== undefined && error !== null}
              autoHideDuration={5000}
              message={ error && t(error.message) }
              onClose={ () => setError(null) }
              action={
                <IconButton size="small" color="inherit" onClick={() => setError(null)}>
                  <MdClose fontSize="small" />
                </IconButton>
              }
          />
        </Grid>
      </Grid>
    </form>
  );
}
