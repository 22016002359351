
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { name, version } from '../../package.json';
import { Dashboard } from './dashboard/Dashboard';
import { DetailForm } from './masterDetail/DetailForm';
import { LoginForm } from './login/LoginForm';
import { MasterForm } from './masterDetail/MasterForm';
import { NavBar } from './navbar/NavBar';
import { PrivateRoute } from './PrivateRoute';
import { ScanForm } from './scan/ScanForm';
import { IUser, UserContext } from "./UserContext";
import { getBasename, getCurrentSiteUrl, getLanguage, getServerUrl } from './Utils';
import { csCZ as gridCsCZ, enUS as gridEnUS } from '@mui/x-data-grid';
import { csCZ as coreCsCZ, enUS as coreEnUS, Localization } from '@mui/material/locale';

export const App = () => {
  const [t] = useTranslation();

  const [user, setUser] = useState<IUser | null>(null);
  const [userMemo, setUserMemo] = useMemo(() => ([user, setUser]), [user, setUser]);

  document.title = t('title');

  console.log(name + "@" + version);
  console.log(getServerUrl());
  console.log(getCurrentSiteUrl());
  console.log(getBasename());

  const browserLang = getLanguage();
  console.log(browserLang);

  const langCoreMap = new Map<string, Localization>([
    ["en", coreEnUS],
    ["cs", coreCsCZ]
  ]);
  let coreLang = langCoreMap.get(browserLang);
  if (coreLang == null)
    coreLang = coreEnUS;

  const langGridMap = new Map<string, any>([
    ["en", gridEnUS],
    ["cs", gridCsCZ]
  ]);
  let gridLang = langGridMap.get(browserLang);
  if (gridLang == null)
    gridLang = gridEnUS;

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeMemo = React.useMemo(
    () => createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: "#9fc519"
          },
        }
      },
      gridLang,
      coreLang as Localization
    ),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={themeMemo}>
      <CssBaseline/>
      <UserContext.Provider value={[userMemo, setUserMemo]}>
        <NavBar/>
        <Routes>
          <Route path="/" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
          <Route path="/login" element={<LoginForm/>}/>
          <Route path="/scan" element={<PrivateRoute><ScanForm/></PrivateRoute>}/>
          <Route path="/master" element={<PrivateRoute><MasterForm/></PrivateRoute>}/>
          <Route path="/detail" element={<PrivateRoute><DetailForm/></PrivateRoute>}/>
        </Routes>
      </UserContext.Provider>
    </ThemeProvider>
  );
}
