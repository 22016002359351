import { IFormData } from "./LoginForm";

export const LS_KEY = "LoginForm.data";

export const storeLoginData = (data: IFormData) => {
  if (data?.remember)
    localStorage.setItem(LS_KEY, JSON.stringify(data));
}

export const getLoginData = () : IFormData | null => {
  const formValuesStr = localStorage.getItem(LS_KEY);
  if (formValuesStr != null)
    return JSON.parse(formValuesStr);
  return null;
}

export const removeLoginData = () => {
  localStorage.removeItem(LS_KEY);
}
