
import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './components/App';
import { getBasename, getLanguage } from './components/Utils';
import t_cs from './i18n/cs.json';
import t_en from './i18n/en.json';


const browserLang = getLanguage();

i18next.init({
  interpolation: { escapeValue: false },
  lng: browserLang,
  fallbackLng: 'en',
  resources: {
    en: { translation: t_en },
    cs: { translation: t_cs }
  }
});
//i18next.changeLanguage('cs');

ReactDOM.render(
  <Router basename={ getBasename() }>
    <I18nextProvider i18n={ i18next }>
      <App/>
    </I18nextProvider>
  </Router>,
  document.getElementById("root")
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("./service-worker.js")
    .then((reg) => console.log("service-worker: registered"))
    .catch((err) => console.log("service-worker: error:", err));
}
