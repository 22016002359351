import { AppBar, IconButton, LinearProgress, Snackbar, Toolbar, Typography } from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowBack, MdClose, MdHome, MdLogout } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router';
import { removeLoginData } from '../login/LocalStorage';
import { UserContext } from '../UserContext';
import * as styles from './NavBar.module.scss';
import { restLogout } from './RestApi';

interface IProps {
}

export const NavBar: FC<IProps> = (props) => {
  const [t] = useTranslation();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    setLoading(true);
    restLogout(user?.authorization || "")
        .then((response) => {
          removeLoginData();
          setUser(null);
        })
        .catch((error) => {
          setError(error);
        });
    setLoading(false);
  };

  const navItems = {
    back: (location.pathname !== "/" && location.pathname !== "/login"),
    label: t("title"),
    logout: (user !== null)
  }

  return (
      <nav>
        <AppBar position="static">
          <Toolbar>
            {
              navItems.back && (
                <>
                  <IconButton size="large" color="inherit" onClick={ () => navigate(-1) }>
                    <MdArrowBack/>
                  </IconButton>
                  <IconButton size="large" color="inherit" onClick={ () => navigate("/") }>
                    <MdHome/>
                  </IconButton>
                </>
              )
            }
            <Typography align="center" variant="h4" sx={{ flexGrow: 1 }}>
              { navItems.label }
            </Typography>
            {
              navItems.logout && (
                <IconButton size="large" color="inherit" onClick={ logout } >
                  <MdLogout/>
                </IconButton>
              )
            }
          </Toolbar>
        </AppBar>
        <Snackbar
            open={error !== undefined && error !== null}
            autoHideDuration={5000}
            message={ error && t(error.message) }
            onClose={ () => setError(null) }
            action={
              <IconButton size="small" color="inherit" onClick={() => setError(null)}>
                <MdClose fontSize="small" />
              </IconButton>
            }
        />
        { loading ? <LinearProgress/> : <></> }
      </nav>
  );
}
