import { Checkbox, FormControlLabel } from "@mui/material";
import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";

interface IProps {
  name: string;
  control: Control<any, object>;
  label: string;
  defaultValue?: boolean;
  required?: boolean;
}

export const ControlledCheckbox : FC<IProps> = (props) => {
  return (
    <Controller
        name={ props.name }
        control={ props.control }
        defaultValue={ props.defaultValue }
        rules={{ required: props.required }}
        render={({ field: { ref, ...field } }) => (
          <FormControlLabel
              control={
                <Checkbox
                    inputRef={ ref }
                    checked={ field.value ?? false }
                    required={ props.required }
                    { ...field }
                />
              }
              label={ props.label }
          />
        )}
    />
  );
}
