import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdGridOn, MdQrCode2 } from 'react-icons/md';
import { Link } from 'react-router-dom';
import * as styles from './Dashboard.module.scss';

interface IProps {
}

export const Dashboard: React.FC<IProps> = () => {
  const [t] = useTranslation();

  return (
    <Grid container spacing={2} direction="row" justifyContent="start" p={3}>
      <Grid item>
        <Link to="/scan" className={styles.link}>
          <MdQrCode2 size="60px"/>
        </Link>
      </Grid>
      <Grid item>
        <Link to="/master" className={styles.link}>
          <MdGridOn size="60px"/>
        </Link>
      </Grid>
    </Grid>
  );
}
