import { Button, Grid, IconButton, Snackbar } from '@mui/material';
import { IRow } from 'components/masterDetail/RestApi';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useLocation, useNavigate } from "react-router-dom";
import { ControlledTextField } from '../controlled/ControlledTextField';
import { UserContext } from '../UserContext';
import { IRowRequestData, restLoadRow, restSaveRow } from './RestApi';

interface IProps {
}

interface IFormData {
  id: string;
  name: string;
}

export const DetailForm: React.FC<IProps> = (props) => {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [row, setRow] = useState<IRow>();
  const [user] = useContext(UserContext);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  if (location.state?.rowId) {
    useEffect(() => {
      setLoading(true);
      const data: IRowRequestData = { id: location.state.rowId };
      restLoadRow(data, user?.authorization || "")
          .then((response) => {
            setRow(response)
            reset(defaultValues);
          })
          .catch((error) => {
            setError(error);
          });
      setLoading(false);
    }, []);
    useEffect(() => {
      reset(defaultValues);
    }, [row]);
  }

  const defaultValues = {
    id: row ? String(row.id) : "",
    name: row ? row.name : ""
  }
  const {handleSubmit, control, getValues, setValue, reset} = useForm<IFormData>({ defaultValues: defaultValues });

  const saveRow = () => {
    setLoading(true);
    const formData: IFormData = getValues();
    const rowData: IRow = {
      id: Number(formData.id),
      name: formData.name
    }
    restSaveRow(rowData, user?.authorization || "")
        .then((response) => {
        })
        .catch((error) => {
          setError(error);
        });
    setLoading(false);
    navigate(-1);
  };

  return (
    <form>
      <Grid container spacing={2} direction="column" justifyContent="center" p={3}>
        <Grid item>
          <ControlledTextField
              name="id"
              label={ t("id") }
              control={ control }
              readOnly={ true }
          />
        </Grid>
        <Grid item>
          <ControlledTextField
              name="name"
              autoFocus={ true }
              required={ true }
              label={ t("name") }
              control= { control }
          />
        </Grid>
        <Grid container spacing={2} direction="row" justifyContent="center" item>
          <Grid item xs={6}>
            <Button type="submit" variant="contained" fullWidth onClick={ handleSubmit(saveRow) }>{ t('save')}</Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth onClick={ () => reset(defaultValues) }>{ t('reset')}</Button>
          </Grid>
        </Grid>
        <Grid item>
          <Snackbar
              open={error !== undefined && error !== null}
              autoHideDuration={5000}
              message={ error && t(error.message) }
              onClose={ () => setError(null) }
              action={
                <IconButton size="small" color="inherit" onClick={() => setError(null)}>
                  <MdClose fontSize="small" />
                </IconButton>
              }
          />
        </Grid>
      </Grid>
    </form>
  );
}
