import { Button, Grid, Typography, LinearProgress, IconButton, Snackbar } from '@mui/material';
import React, { FocusEvent, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { ControlledTextField } from '../controlled/ControlledTextField';
import { UserContext } from '../UserContext';
import { restCreateNewItem, restLoadItem } from './RestApi';
import * as styles from './ScanForm.module.scss';

interface IProps {
}

interface IFormData {
  id: number;
  barcode: string;
  type: string;
  quantity: number;
}

export const ScanForm: React.FC<IProps> = () => {
  const [t] = useTranslation();
  const defaultValues = {
    id: undefined,
    barcode: "",
    type: "",
    quantity: 1
  }
  const {handleSubmit, control, getValues, setValue, reset} = useForm<IFormData>();
  const [user] = useContext(UserContext);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const createNewItem = () => {
    setLoading(true);
    restCreateNewItem(getValues(), user?.authorization || "")
        .then((response) => {
        })
        .catch((error) => {
          setError(error);
        });
    setLoading(false);
  };

  const loadItem = () => {
    setLoading(true);
    restLoadItem(getValues(), user?.authorization || "")
        .then((response) => {
          if (response.id)
            setValue("id", response.id, { shouldValidate: true });
          if (response.quantity)
            setValue("quantity", response.quantity, { shouldValidate: true });
        })
        .catch((error) => {
          setError(error);
        });
    setLoading(false);
  };

  const onFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
    e.target.select();
  }

  return (
    <form>
      <Grid container spacing={2} direction="column" justifyContent="center" p={3}>
        <Grid item>
          { loading ? <LinearProgress/> : <></> }
        </Grid>
        <Grid item>
          <Typography variant="h5">{ t('scan') }</Typography>
        </Grid>
        <Grid item>
          <ControlledTextField
              name="barcode"
              inputMode="none"
              autoFocus={ true }
              required={ true }
              label={ t("barcode") }
              control= { control }
              defaultValue=""
          />
        </Grid>
        <Grid item>
          <ControlledTextField
              name="quantity"
              type="number"
              required={ true }
              label={ t("quantity") }
              control= { control }
              defaultValue="1"
              min={ 0 }
          />
        </Grid>
        <Grid container spacing={2} direction="row" justifyContent="center" item>
          <Grid item xs={4}>
            <Button type="submit" variant="contained" fullWidth onClick={ handleSubmit(createNewItem) }>{ t('save')}</Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth onClick={ () => loadItem() }>{ t('load')}</Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth onClick={ () => reset(defaultValues) }>{ t('reset')}</Button>
          </Grid>
        </Grid>
        <Grid item>
          <Snackbar
              open={error !== undefined && error !== null}
              autoHideDuration={5000}
              message={ error && t(error.message) }
              onClose={ () => setError(null) }
              action={
                <IconButton size="small" color="inherit" onClick={() => setError(null)}>
                  <MdClose fontSize="small" />
                </IconButton>
              }
          />
        </Grid>
      </Grid>
    </form>
  );
}
