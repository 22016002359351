import { getServerUrl } from "../Utils";

export type ILogoutResponse = {
}

export async function restLogout(authorization: string) : Promise<ILogoutResponse> {
  const controller = new AbortController();
  const response = await fetch(
      getServerUrl() + "/logout",
      {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Basic " + authorization
        },
        method: "POST",
        mode: "cors",
        credentials: "include",
        signal: controller.signal
      });
  //controller.abort();
  //console.table(response);
  if (!response.ok)
    throw new Error(String(response.status));
  return {};
}
